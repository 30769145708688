import React from "react";
import "./App.css";
import Navbar, { Link, Nav } from "../navbar/Navbar";

function App() {
  return (
    <div className="app">
      <header>
        <Navbar
          nav={[
            new Link("Home", "home.andreasjuergensen.de"),
            new Link("Skat", "skat.andreasjuergensen.de"),
            new Nav("Profile", [
              new Link("View Profile", "home.andreasjuergensen.de/viewProfile"),
              new Link("Logout", "home.andreasjuergensen.de/logout"),
            ]),
          ]}
          title={new Link("andreasjuergensen.de", "home.andreasjuergensen.de")}
        />
      </header>
      <main>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor rutrum pharetra. Duis vitae nisl ac nulla sagittis ornare vel congue massa. In fringilla arcu nec lacus scelerisque ullamcorper. Nam volutpat, ex at semper condimentum, magna augue euismod libero, in lobortis tellus ex sit amet libero. Nullam bibendum id arcu sit amet vestibulum. Mauris ut tempor nulla. Sed dui sapien, blandit ut est id, lobortis faucibus justo. Suspendisse potenti. Vestibulum erat justo, sagittis sed ullamcorper ac, elementum ut massa. Donec ac lobortis arcu. Morbi a lorem ullamcorper, pretium ante et, condimentum enim. Integer vitae metus eu eros ullamcorper lacinia eget tincidunt lorem. Duis sodales fermentum suscipit.

Proin imperdiet ut risus sed convallis. Praesent nisi purus, semper non nisi id, fringilla ultrices nibh. Quisque in sem commodo, rhoncus erat quis, hendrerit ante. Aenean viverra est ante, vitae ullamcorper urna bibendum vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras fermentum tempor dignissim. Phasellus et ex enim. Maecenas a suscipit ipsum. Donec faucibus vitae augue sed auctor. In id nunc feugiat, blandit urna in, volutpat nisi. Pellentesque malesuada semper sodales. Vivamus porta ipsum sit amet lorem lacinia, vel suscipit odio fringilla. Aliquam venenatis semper tortor id pretium. Aliquam vel bibendum purus. Proin lacinia dictum nibh, et dignissim nibh tincidunt non. Mauris consectetur sapien massa, a luctus orci vestibulum vitae.

Sed pellentesque in velit tempus ultrices. Nunc metus elit, malesuada quis risus vitae, cursus auctor mauris. Sed vitae mi ac libero tincidunt fringilla. Pellentesque a pellentesque eros, a ultrices metus. Maecenas scelerisque cursus ante a mattis. Sed a eros et ligula tempor venenatis. Nunc est nibh, volutpat et enim at, vestibulum semper dui. Vivamus laoreet tempor augue eget auctor. Etiam sit amet condimentum nulla, quis sagittis ipsum. Nulla maximus pharetra erat, at vulputate velit ullamcorper sit amet.

Etiam non velit non felis interdum egestas ut sed sapien. Duis feugiat tincidunt mattis. Maecenas aliquam justo non nunc pellentesque, vel sodales ligula dignissim. In urna urna, aliquam vel varius nec, pellentesque dictum ante. Sed ut auctor nisl. Vivamus a ante in lacus porttitor luctus nec in libero. Morbi laoreet lectus quis tortor ultrices maximus.

Suspendisse eu nisi ut elit mattis dapibus et a est. Sed fermentum bibendum turpis. Phasellus nunc ante, fringilla vitae tortor quis, mattis suscipit nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent posuere nisl quis urna vulputate, non laoreet felis ultrices. In gravida nulla sed massa accumsan tempor. Maecenas vehicula eros id tempor malesuada. Sed elementum nulla urna, feugiat aliquet diam tincidunt non. Ut tempus nibh vel sem condimentum porttitor. Duis lacus augue, luctus vel massa at, tempor viverra nulla. Aliquam mi erat, lobortis nec odio quis, pellentesque congue sem. Cras eget mollis metus. Cras non aliquam tellus. Nunc faucibus congue pharetra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam vitae augue elit.</main>
      <footer>© Andreas Jürgensen</footer>
    </div>
  );
}

export default App;
