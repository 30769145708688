import React, { useState } from "react";
import "./Navbar.css";

export class Link {
  title: string;
  link: string;

  constructor(title: string, link: string) {
    this.title = title;
    this.link = "http://" + link;
  }
}

export class Nav {
  title: string;
  details: Link[];

  constructor(title: string, details: Link[]) {
    this.title = title;
    this.details = details;
  }
}

function Navbar(props: { nav: (Nav | Link)[]; title: Link }) {
  const [showNav, setShowNav] = useState(false);
  return (
    <div className="navbar">
      <div className="title">
        <a href={props.title.link}>{props.title.title}</a>{" "}
      </div>
      <label>
        <input
          type="checkbox"
          checked={showNav}
          onClick={() => setShowNav(!showNav)}
        />
        <span>
          <div className="more" />
        </span>
      </label>
      <label className="nav">
        <input type="checkbox" checked={showNav} />
        <ul>
          {props.nav.map((nav) => (
            <li>
              {nav instanceof Link && <a href={nav.link}>{nav.title}</a>}
              {nav instanceof Nav && (
                <Subnav title={nav.title} details={nav.details} />
              )}
            </li>
          ))}
        </ul>
      </label>
    </div>
  );
}

function Subnav(props: Nav) {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <label>
        <input
          type="checkbox"
          checked={showDetails}
          onClick={() => setShowDetails(!showDetails)}
        />
        {props.title}
      </label>
      <input type="checkbox" checked={showDetails} />
      <ul className="subnav">
        {props.details.map((detail) => (
          <li>
            <a href={detail.link}>{detail.title}</a>
          </li>
        ))}
      </ul>
    </>
  );
}

export default Navbar;
